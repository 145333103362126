import React from 'react';
import LayoutTextPage from '../layout/layoutTextPage';
import { mq, theme } from '../theme';

class PrivacyPage extends React.PureComponent<{}, {}> {
    public render() {
        return (
            <LayoutTextPage title='Privacy Voorwaarden'>
                <div
                    css={mq({
                        maxWidth: ['100%', '900px'],
                        margin: 'auto',
                        padding: '20px',
                        fontFamily: 'system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif',
                        lineHeight: '1.6',
                        color: '#333',
                    })}
                >
                    <div css={{
                        backgroundColor: '#f9f9f9',
                        padding: '20px',
                        marginBottom: '20px',
                        borderRadius: '4px',
                        boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                    }}>
                        <div css={{
                            '& p': {
                                marginBottom: '15px',
                            },
                            '& ul': {
                                backgroundColor: 'white',
                                borderRadius: '4px',
                                padding: '15px 30px',
                                marginBottom: '15px',
                                boxShadow: '0 1px 3px rgba(0,0,0,0.1)',
                            },
                            '& li': {
                                marginBottom: '10px',
                                paddingLeft: '10px',
                                position: 'relative',
                                '&:before': {
                                    content: '"•"',
                                    color: '#007bff',
                                    position: 'absolute',
                                    left: '-15px',
                                    fontWeight: 'bold',
                                },
                            },
                            '& h2': {
                                color: '#007bff',
                                borderBottom: '1px solid #e0e0e0',
                                paddingBottom: '10px',
                                marginTop: '30px',
                                marginBottom: '15px',
                            },
                        }}>
                            <h1 css={{
                                color: '#007bff',
                                borderBottom: '2px solid #007bff',
                                paddingBottom: '10px',
                                marginBottom: '20px',
                            }}>Privacyverklaring</h1>

                            <p>Via onze website worden privacygevoelige gegevens, oftewel persoonsgegevens verwerkt. Smart Sales Company B.V. acht een zorgvuldige omgang met persoonsgegevens van groot belang. Persoonlijke gegevens worden door ons dan ook zorgvuldig verwerkt en beveiligd.</p>

                            <p>Bij deze verwerking houden wij ons strikt aan de eisen die de privacywetgeving stelt. Dit betekent onder andere dat:</p>

                            <ul>
                                <li>Wij duidelijk vermelden met welke doeleinden wij persoonsgegevens verwerken. Dat doen wij via deze privacyverklaring;</li>
                                <li>Wij onze verzameling van persoonsgegevens beperken tot alleen de persoonsgegevens die nodig zijn voor legitieme doeleinden;</li>
                                <li>Wij u eerst vragen om uitdrukkelijke toestemming om uw persoonsgegevens te verwerken in gevallen waarin uw toestemming is vereist;</li>
                                <li>Wij passende beveiligingsmaatregelen nemen om uw persoonsgegevens te beschermen en dat ook eisen van partijen die in onze opdracht persoonsgegevens verwerken;</li>
                                <li>Wij uw recht respecteren om uw persoonsgegevens op uw aanvraag ter inzage te bieden, te corrigeren of te verwijderen.</li>
                            </ul>

                            <p>Smart Sales Company B.V. is verantwoordelijk voor de gegevensverwerking. In deze privacyverklaring leggen wij uit welke persoonsgegevens wij verzamelen en gebruiken en met welk doel. Wij adviseren u deze verklaring zorgvuldig door te nemen.</p>

                            <p css={{ fontStyle: 'italic', color: '#666' }}>Deze privacyverklaring is voor het laatst gewijzigd op 09-12-2024.</p>

                            <h2>Gebruik van persoonsgegevens</h2>
                            <p>Bij het gebruiken van onze website verkrijgen wij bepaalde gegevens van u. Dat kunnen persoonsgegevens zijn. Wij bewaren en gebruiken uitsluitend de persoonsgegevens die rechtstreeks door u worden opgegeven, in het kader van de door u gevraagde dienst, of waarvan bij opgave duidelijk is dat ze aan ons worden verstrekt om te verwerken.</p>

                            <p>Wij gebruiken de volgende gegevens voor de in deze privacyverklaring genoemde doelen:</p>
                            <ul>
                                <li>NAW-gegevens</li>
                                <li>Telefoonnummer</li>
                                <li>Factuuradres</li>
                                <li>E-mailadres</li>
                                <li>Betalingsgegevens</li>
                                <li>Geslacht</li>
                                <li>Geboortedatum</li>
                            </ul>

                            <h2>Met welk doel en op basis van welke grondslag wij persoonsgegevens verwerken</h2>
                            <p>Smart Sales Company B.V. verwerkt uw persoonsgegevens voor de volgende doelen:</p>
                            <ul>
                                <li>U te kunnen bellen of e-mailen indien dit nodig is om onze dienstverlening uit te kunnen voeren</li>
                                <li>U te informeren over wijzigingen van onze diensten en producten</li>
                                <li>Om goederen en diensten bij u af te leveren</li>
                                <li>Smart Sales Company B.V. volgt uw surfgedrag over verschillende websites waarmee wij onze producten en diensten afstemmen op uw behoefte.</li>
                            </ul>

                            <h2>Afhandeling van bestellingen</h2>
                            <p>Wanneer u bij ons een bestelling plaatst, maken wij voor de afhandeling daarvan gebruik van uw persoonsgegevens. Indien dat nodig is voor een goede afhandeling kunnen wij uw persoonsgegevens ook aan derden verstrekken.</p>

                            <h2>Reclame</h2>
                            <p>Wij kunnen u, naast de informatie op onze website, ook op de hoogte brengen van onze nieuwe producten en diensten:</p>
                            <ul>
                                <li>Per e-mail</li>
                                <li>Via sociale media</li>
                                <li>Telefonisch</li>
                            </ul>

                            <h2>Contactformulier en nieuwsbrief</h2>
                            <p>Wij bieden via onze website de mogelijkheid om vragen te stellen middels een contactformulier, waarbij u gevraagd wordt diverse gegevens in te vullen om uw vraag te behandelen. U kiest zelf welke gegevens u daarbij verstrekt. De gegevens die u ons toestuurt, worden bewaard zolang als naar de aard van het formulier of de inhoud van uw e-mail nodig is voor de volledige beantwoording en afhandeling daarvan.</p>

                            <p>Indien u ons via het contactformulier verzoekt om contact met u opnemen voor de aankoop van een product of dienst, nemen wij doorgaans telefonisch contact met u op.</p>

                            <p>Wij bieden een nieuwsbrief waarmee wij geïnteresseerden willen informeren over onze producten en/of diensten. Iedere nieuwsbrief bevat een link waarmee u zich kunt afmelden. Uw e-mailadres wordt automatisch toegevoegd aan de lijst van abonnees.</p>

                            <h2>Publicatie</h2>
                            <p>Wij publiceren uw klantgegevens niet.</p>

                            <h2>Advertenties</h2>
                            <p>Onze website kan advertenties vertonen.</p>

                            <h2>Delen van persoonsgegevens met derden</h2>
                            <p>Smart Sales Company B.V. deelt uw persoonsgegevens met verschillende derden als dit noodzakelijk is voor het uitvoeren van de overeenkomst en om te voldoen aan een eventuele wettelijke verplichting. Met bedrijven die uw gegevens verwerken in onze opdracht, sluiten wij een verwerkersovereenkomst om te zorgen voor eenzelfde niveau van beveiliging en vertrouwelijkheid van uw gegevens. Smart Sales Company B.V. verkoopt uw gegevens niet aan derden.</p>

                            <h2>Cookies</h2>
                            <p>Op onze website maken wij gebruik van cookies. Ook derden die door ons zijn ingeschakeld plaatsen cookies.</p>
                            <p>Wanneer u onze website voor het eerst bezoekt, wordt er een mededeling getoond waarin wij u uitleggen waarom wij cookies gebruiken. Uw verder gebruik van onze website vatten wij op als toestemming voor dit gebruik van cookies.</p>
                            <p>U kunt cookies uitschakelen via uw browserinstellingen. Houd er rekening mee dat onze website dan mogelijk niet optimaal werkt.</p>

                            <h2>Beveiliging</h2>
                            <p>Wij treffen beveiligingsmaatregelen om misbruik van en ongeautoriseerde toegang tot persoonsgegevens te voorkomen, waaronder:</p>
                            <ul>
                                <li>Toegang tot persoonsgegevens wordt afgeschermd met een gebruikersnaam, wachtwoord en 2FA.</li>
                                <li>Wij maken gebruik van beveiligde verbindingen (SSL) voor de overdracht van gegevens.</li>
                            </ul>

                            <h2>Bewaartermijnen</h2>
                            <p>De hierboven beschreven persoonsgegevens worden maximaal zes maanden bewaard, ongeacht of uw bestelling volledig is afgehandeld. Na deze periode worden alle gegevens, inclusief opt-in gegevens, volledig gewist.</p>

                            <h2>Inzicht in Opt-in Gegevens</h2>
                            <p>U heeft te allen tijde het recht om inzicht te krijgen in de opt-in gegevens die wij van u hebben geregistreerd. Om dit te doen, kunt u een verzoek indienen door een e-mail te sturen naar verzoeken@quicknetsolutions.nl. Vermeld in uw e-mail duidelijk uw volledige naam en eventuele aanvullende informatie die ons kan helpen uw gegevens te lokaliseren.</p>

                            <h2>Websites van derden</h2>
                            <p>Deze privacyverklaring is niet van toepassing op websites van derden die door middel van links met onze webwinkel zijn verbonden. Wij kunnen niet garanderen dat deze derden op een betrouwbare of veilige manier met uw persoonsgegevens omgaan. Wij raden u aan de privacyverklaring van deze websites te lezen alvorens van deze websites gebruik te maken.</p>

                            <h2>Wijzigingen in deze privacyverklaring</h2>
                            <p>Wij behouden ons het recht voor om wijzigingen aan te brengen in deze privacyverklaring. Wij adviseren u daarom om deze verklaring regelmatig te raadplegen.</p>

                            <h2>Inzage en wijzigen van uw gegevens</h2>
                            <p>Voor vragen over ons privacybeleid of verzoeken tot inzage, wijziging, verwijdering of beperking van uw persoonsgegevens kunt u contact met ons opnemen.</p>

                            <p>Om misbruik te voorkomen, kunnen wij u vragen om uzelf adequaat te identificeren. Voor inzage in persoonsgegevens gekoppeld aan een cookie, dient u een kopie van het betreffende cookie mee te sturen, te vinden in de instellingen van uw browser.</p>

                            <h2>Autoriteit Persoonsgegevens</h2>
                            <p>Als u klachten heeft over de verwerking van uw persoonsgegevens, helpen wij u graag verder. U heeft ook het recht om een klacht in te dienen bij de Autoriteit Persoonsgegevens.</p>
                        </div>
                    </div>
                </div>
            </LayoutTextPage>
        )
    }
}

export default PrivacyPage;